import axios from "axios";
import { ApiConfig, API_KEY } from "../config/api.config";

export class TasksService {

    static async createNoAuthScheduledTask(data) {
        const request = await axios.post(ApiConfig.createNoAuthScheduledTask, data, {
            headers: {
                Authorization: `apikey ${API_KEY}`
            }
        });
        return request.data.status === 200 ? Promise.resolve(request.data) : Promise.reject(request.data);
    }

    static async calculateFare(data) {
        const request = await axios.post(ApiConfig.calculateFare, data, {
            headers: {
                Authorization: `apikey ${API_KEY}`
            }
        });
        return request.data.status === 200 ? Promise.resolve(request.data) : Promise.reject(request.data);
    }
}