export class PaymentGatewayService {

    static async createCheckoutSession({ amount }) {
        const response = await fetch('https://eu-test.oppwa.com/v1/checkouts', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer OGE4Mjk0MTc0ZTczNWQwYzAxNGU3OGNmMjY2YjE3OTR8cXl5ZkhDTjgzZQ==',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: `entityId=8a8294174e735d0c014e78cf26461790&amount=${amount}&currency=MUR&paymentType=DB`
        });
        return response.status === 200 ? response.data : null;
    }

    static async checkPaymentStatus({ resourcePath }) {
        const response = await fetch('https://eu-test.oppwa.com/' + resourcePath, {
            headers: {
                'Authorization': 'Bearer OGE4Mjk0MTc0ZTczNWQwYzAxNGU3OGNmMjY2YjE3OTR8cXl5ZkhDTjgzZQ=='
            }
        });
        return response.status === 200 ? response.data : null;
    }
}