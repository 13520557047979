<template>
  <div class="container-fluid">
    <div class="row">
      <div class="driver-header" :class="{ mapShown: pickFareLocation != '' && dropFareLocation != '' }">
        <div class="header-driver-transparent-bg" :class="{
  mapShown: pickFareLocation != '' && dropFareLocation != '',
}">
          <div class="py-md-5 py-3 mob-header">
            <div class="col-md-7 col-lg-5">
              <div class="card">
                <div class="pt-n4 pl-0">
                  <ul class="
                      tab__title
                      d-flex
                      justify-content-around
                      list-group-horizontal
                      cat-header
                      py-4
                    ">
                    <li @click="activate(1)" :class="{ active: isActive == 1 }"
                      class="d-flex flex-column position-relative" id="taxi-ride">
                      <!-- <i class="fa fa-car isize"></i> -->
                      <img src="../assets/imgs/car.png" alt=",," class="car isize ml-3" />
                      <span class="rsize car">Taxi ride</span>
                    </li>
                    <li @click="activate(2)" :class="{ active: isActive == 2 }" class="d-flex flex-column"
                      id="deliver-item">
                      <!-- <i class="fa fa-utensils isize"></i > -->
                      <img src="../assets/imgs/delivery.png" alt=",," class="isize ml-2" />
                      <span class="rsize">Deliver Item</span>
                    </li>
                  </ul>
                  <ul class="tab_content tab__title" style="padding-left: 0">
                    <li>
                      <div class="card-body">
                        <b-form v-model="valid" ref="form" lazy-validation @submit.prevent="validate">
                          <div v-if="carouselIndex == 0">
                            <div class="row mt-n3" v-if="isActive == 2">
                              <div class="col-md-7 pt-3">
                                <p class="font-18 text-center">
                                  Select item to deliver
                                </p>
                              </div>
                              <div class="col-md-5">
                                <b-form-group>
                                  <b-form-select v-model="deliveryItemType" class="card-form mt-1 border-radjust"
                                    required :options="food"></b-form-select>
                                </b-form-group>
                              </div>
                            </div>
                            <div class="flex align-center mb-4">
                              <!-- <h2 v-if="isActive == 1" class="mx-4">
                                Book your ride
                              </h2> -->
                              <div v-if="isActive == 1" class="maintainance">
                                <!-- <span class="mx-3">Site maintainance ongoing</span> -->
                                <!-- <h2 class="mx-3">
                                  Please book on whatsapp &#10140;
                                </h2> -->
                                <span class="mx-3">30mins + in advance recommended</span>
                                <h2 class="mx-3">
                                  Please book your trip below
                                </h2>
                              </div>
                              <!-- <a href="https://api.whatsapp.com/send/?phone=23059150708&text&app_absent=0"
                                class="normal-text">
                                <div class="flex flex-column align-center whatsapp-btn">
                                  <img src="../assets/imgs/whatsapp.png" alt="image" style="width: 30px" />
                                  +23059150708
                                </div>
                              </a> -->
                              <div class="flex flex-column whatsapp-btn">
                                <a class="float-button" target="_blank"
                                  href="https://api.whatsapp.com/send/?phone=23059150708&text&app_absent=0">
                                  <span>START CHAT</span>
                                  <i class="fa fa-whatsapp" aria-hidden="true"></i>
                                </a>
                                <span class="whatsapp-number">+(230) 59150708</span>
                              </div>

                            </div>
                            <b-form-group class="input-field-group">
                              <i class="fa fa-map-marker-alt size"></i>
                              <div class="text-position w-100">
                                <span class="size-lable" v-if="isActive == 1">Pickup from</span>
                                <span class="size-lable" v-if="isActive == 2">Pickup item at:</span>
                              </div>

                              <div class="py-2">
                                <b-form-input :rules="[
  (v) =>
    !!v || 'Pick-up Location is required',
]" id="searchFarePickUp" v-model="pickFareLocation" placeholder="Enter here" @click="scrollToElementTop"
                                  class="cal-field pl-4 pt-3" clearable autocomplete="false"></b-form-input>
                                <span class="clear-btn" v-if="pickFareLocation.length != 0" @click="clearPickUp"><i
                                    class="fa fa-times-circle size-icon"></i></span>
                              </div>
                            </b-form-group>
                            <b-form-group class="input-field-group py-2">
                              <i class="fa fa-map-marker-alt size"></i>
                              <div class="text-position w-100">
                                <span class="size-lable" v-if="isActive == 1">Drop-off At</span>
                                <span class="size-lable" v-if="isActive == 2">Delivary location</span>
                              </div>
                              <b-form-input v-model="dropFareLocation" :rules="[
  (v) => !!v || 'Drop-off Location is required',
]" id="searchFareDropOff" @click="scrollToElementTop" placeholder="Enter here"
                                class="cal-field pl-4 pt-3"></b-form-input>
                              <span class="clear-btn" v-if="dropFareLocation.length != 0" @click="clearDropOff"><i
                                  class="fa fa-times-circle size-icon"></i></span>
                            </b-form-group>

                            <div v-if="
  pickFareLocation !== '' &&
  dropFareLocation !== '' &&
  isActive == 1
">
                              <div class="switch-container d-inline">
                                <b-button :variant="returnd ? 'success' : 'danger'" size="sm" name="switch"
                                  @click="toogleSwitch" class="switch-btn" :class="returnd ? '' : 'return'">
                                  <p>
                                    {{
    returnd
      ? "One-way (drop only)"
      : "Two-way (drop &amp; return)"
}}
                                  </p>
                                </b-button>
                              </div>
                              <div class="d-inline">
                                <span class="underline" @click="toogleSwitch">Switch</span>
                              </div>
                              <div class="
                                  d-flex
                                  no-column
                                  border-bottom
                                  mb-2
                                  mt-3
                                  px-2
                                ">
                                <p class="bold mb-1 flex-grow-1">
                                  Daytime: <span class="red">8am-3.59pm</span>
                                </p>
                                <p class="mb-0 color-green">
                                  <span v-if="returnd">
                                    {{ estimatedPrice }}rs
                                  </span>
                                  <span v-else>
                                    {{ estimatedPriceReturn }}rs
                                  </span>
                                </p>
                              </div>
                              <div class="
                                  d-flex
                                  no-column
                                  border-bottom
                                  b-b-n
                                  px-2
                                ">
                                <div class="flex-grow-1">
                                  <p class="bold">
                                    Rush Hours:
                                    <span class="red d-none d-md-inline">4pm-8.59pm &amp; 6am-7.59am</span>
                                    <span class="red d-block d-md-none">4pm-8.59pm &amp; 6am-7.59am</span>
                                  </p>
                                </div>
                                <p class="mb-0 color-green">
                                  <span v-if="returnd">
                                    {{ estimatedRushHourPrice }}rs
                                  </span>
                                  <span v-else>
                                    {{ estimatedRushHourPriceReturn }}rs
                                  </span>
                                </p>
                              </div>
                              <div class="d-flex no-column mb-1 b-b-n px-2">
                                <p class="bold mb-1 flex-grow-1">
                                  Night: <span class="red">9pm-5.59am</span>
                                </p>
                                <p class="mb-0 color-green">
                                  <span v-if="returnd">
                                    {{ estimatedNightHourPrice }}rs
                                  </span>
                                  <span v-else>
                                    {{ estimatedNightHourPriceReturn }}rs
                                  </span>
                                </p>
                              </div>
                              <div class="d-flex no-column mb-1 b-b-n px-2">
                                <p class="bold mb-1 flex-grow-1">
                                  The fares are for 4 seater vehicles.
                                </p>
                              </div>
                            </div>
                            <b-form-group>
                              <b-button @click="setActiveItem('1')" class="btn-block bg-primary btn-lg mt-3"
                                name="request-now" v-if="
  pickFareLocation !== '' &&
  dropFareLocation !== '' &&
  isActive == 1
">Request a Driver</b-button>
                            </b-form-group>

                            <div v-if="isActive == 2">
                              <div class="
                                  row
                                  d-flex
                                  flex-row
                                  justify-content-center
                                  my-3
                                ">
                                <p>
                                  <i class="fa fa-dot-circle mt-1 pr-2"></i>
                                  Estimate Price :
                                </p>
                                <p class="mx-3 color-green">
                                  {{ estimatedPrice }}rs
                                </p>
                              </div>

                              <div class="row">
                                <div class="col-md-6">
                                  <p class="fs-5 mb-1">Sender Phone Number</p>
                                  <b-form-group>
                                    <b-form-input v-model="phone" :rules="[
  (v) =>
    !!v || 'Phone number is required',
]" class="
                                        card-form
                                        border-radjust
                                        number
                                        pl-3
                                      " required></b-form-input>
                                  </b-form-group>
                                </div>
                                <div class="col-md-6">
                                  <b-form-group>
                                    <p class="fs-5 mb-1">
                                      Receiver Phone Number
                                    </p>
                                    <b-form-input v-model="rphone" :rules="[
  (v) =>
    !!v || 'Phone number is required',
]" class="
                                        card-form
                                        border-radjust
                                        number
                                        pl-3
                                      " required></b-form-input>
                                  </b-form-group>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-6 col-sm-6 py-2">
                                  <b-form-group class="border-btm width-adjust1">
                                    <datepicker v-model="date" placeholder="Select Date" class="
                                        card-form
                                        dateTime
                                        b-form-btn-label-control
                                        dropdown
                                        b-form-datepicker
                                        card-form
                                        dateTime
                                        form-control
                                        show
                                        form-input-adapt
                                        border-rad1
                                      "></datepicker>
                                  </b-form-group>
                                </div>
                                <div class="col-md-6 col-sm-6 py-2">
                                  <Timepicker v-model="time" :required="true" :submitted="submitted" />
                                </div>

                                <div class="col-12">
                                  <b-form-group class="pt-2">
                                    <b-overlay :show="busy" rounded v-if="!showLoader" opacity="0.6" spinner-small
                                      spinner-variant="primary" class="d-block">
                                      <b-button type="submit" name="confirm-booking"
                                        class="form-control btn-lg order-btn" :disabled="
  pickFareLocation !== '' &&
    dropFareLocation !== ''
    ? false
    : true
" variant="success">Place Order</b-button>
                                    </b-overlay>
                                    <div v-if="showLoader" class="loader" id="loader">
                                      Loading...
                                    </div>
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div v-if="carouselIndex == 1">
                            <div class="row">
                              <div class="col-12 col-lg-6 col-sm-12">
                                <b-form-group class="">
                                  <b-form-input v-model="customerName" :rules="[(v) => !!v || 'Name is required']"
                                    placeholder="Add Name" class="card-form border-radjust" required></b-form-input>
                                </b-form-group>
                              </div>
                              <div class="col-12 col-lg-6 col-sm-12">
                                <div class="row position-relative number">
                                  <div class="position-absolute">
                                    <span class="font-16">
                                      <vue-country-code @onSelect="onCountrySelect" :preferredCountries="['mu']">
                                      </vue-country-code>
                                    </span>
                                  </div>
                                  <div class="col-12">
                                    <b-form-group>
                                      <b-form-input v-model="phone" :rules="[
  (v) =>
    !!v || 'Phone number is required',
]" placeholder="Phone number" class="card-form border-radjust number" required>
                                      </b-form-input>
                                    </b-form-group>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 col-sm-6 py-2">
                                <!-- <div class="text-position3"><span class="size-lable">Date</span></div> -->
                                <b-form-group class="border-btm width-adjust1">
                                  <datepicker v-model="date" placeholder="Select Date" class="
                                      card-form
                                      dateTime
                                      b-form-btn-label-control
                                      dropdown
                                      b-form-datepicker
                                      card-form
                                      dateTime
                                      form-control
                                      show
                                      form-input-adapt
                                      border-rad1
                                    "></datepicker>
                                </b-form-group>
                              </div>
                              <div class="col-md-6 col-sm-6 py-2">
                                <Timepicker v-model="time" :required="true" :submitted="submitted" />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6 col-sm-6">
                                <b-form-group class="padding-adjust">
                                  <span class="text-colour">Vehicle required</span>

                                  <b-form-select v-model="instruction" class="card-form mt-1 border-radjust" required
                                    :options="vehacles"></b-form-select>
                                </b-form-group>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                <b-form-group class="padding-adjust">
                                  <span class="text-colour">Payment Method</span>

                                  <b-form-select v-model="paymentMethod" class="card-form mt-1 border-radjust" required
                                    :options="paymentMethods"></b-form-select>
                                </b-form-group>
                              </div>
                            </div>

                            <b-form-group class="pt-2">
                              <b-overlay :show="busy" rounded v-if="!showLoader" opacity="0.6" spinner-small
                                spinner-variant="primary" class="d-block">
                                <b-button type="submit" name="confirm-booking" class="form-control btn-lg" :disabled="
  pickFareLocation !== '' &&
    dropFareLocation !== ''
    ? false
    : true
" variant="success">Confirm Taxi Booking</b-button>
                              </b-overlay>
                              <div v-if="showLoader" class="loader" id="loader">
                                Loading...
                              </div>
                            </b-form-group>
                          </div>
                        </b-form>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="directionMap" v-show="pickFareLocation != '' && dropFareLocation != ''"></div>
    </div>
    <div class="row d-md-none d-block mt-3">
      <div class="col-12">
        <div class="img-bg h-header">
          <div class="header-bg-transparent p-5">
            <h2 class="text-white text-center pt-4">
              Moving Mauritius, <br />Everyday
            </h2>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showModal" id="modal-scoped" centered>
      <template #default="{ hide }">
        <div class="d-flex justify-content-center mb-5">
          <span class="icon-span">
            <i class="fa fa-check-circle"></i>
          </span>
        </div>
        <h3 class="text-center">Success!</h3>

        <h4 class="text-center mb-5" v-if="isActive == 1">
          Driver will be assigned!
        </h4>
        <h4 class="text-center mb-5" v-if="isActive == 2">
          You will get your order soon!
        </h4>
        <form v-if="cardSubmit" action="https://viteapp.co/" class="paymentWidgets" data-brands="VISA MASTER AMEX">
        </form>
        <div class="pb-2">
          <button @click="hide()" block class="w-100 btn btn-success">
            OK
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import firebase from "firebase";
import VueCountryCode from "vue-country-code";
import Datepicker from "vuejs-datepicker";
import { PaymentGatewayService } from "../services/payment-gateway-service";
import { TasksService } from "../services/tasks.service";
const Timepicker = () => import("./Timepicker.vue");

export default {
  components: {
    Datepicker,
    VueCountryCode,
    Timepicker,
  },
  data() {
    return {
      submitted: false,
      isActive: 1,

      options: [
        { value: "OneWay", text: "One Way" },
        { value: "Return", text: "Returned" },
      ],
      vehacles: [
        { text: "4 seats - normal taxi", value: "4" },
        { text: "6 seats - premium", value: "6" },
        { text: "7- 15 seats - Van", value: "15" },
      ],
      paymentMethods: [
        { text: "Cash", value: "Cash" },
        // { text: "Card", value: "Card" },
      ],
      food: [
        { text: "Food", value: "Food" },
        { text: "Grocery", value: "Grocery" },
        { text: "Medicine", value: "Medicine" },
        { text: "Other", value: "Other" },
      ],

      error: "",
      busy: false,
      calculatorHeading: "Fare Estimator",
      show: false,
      valid: true,
      returnd: true,
      lazy: true,
      showLoader: false,
      loading: false,
      map: null,
      showModal: false,
      formValues: {},
      successDialog: false,
      items: [
        {
          src: "/driver.jpg",
          text1: "Safe. Easy.",
          text2: "Affordable Rides",
          textClass: "",
        },
        {
          src: "/drive_bg.png",
          text1: "Friendly,",
          text2: "Helpful Drivers",
          textClass: "",
        },
        {
          src: "/Driver_airport_auto_x2.jpg",
          text1:
            "Airport Transfers | Meetings | Work | Parties | Weddings | Conferences",
          textClass: "smaller",
        },
      ],
      imgs: [
        `url(${require("../assets/imgs/header-img.png")})`,
        `url(${require("../assets/imgs/driver1.jpeg")})`,
        `url(${require("../assets/imgs/driver2.jpeg")})`,
      ],
      pickFareLocation: "",
      dropFareLocation: "",
      pickupAddressFareLocation: {},
      dropoffAddressFareLocation: {},
      pickupLatLng: {},
      dropoffLatLng: {},
      fareForm: true,
      intDialogVisible: false,
      dialog: false,
      privacyPolicyDialogVisible: false,
      date: null,
      pickupDate: false,
      cardSubmit: false,
      hours: "",
      minutes: "",
      journeyType: "OneWay",
      pickLocation: "",
      dropLocation: "",
      phone: "",
      rphone: "",
      customerName: "",
      instruction: "4",
      paymentMethod: "Cash",
      checkoutId: "",
      rideType: "0",
      deliveryItemName: "",
      deliveryItemType: "Food",
      estimatedPrice: 0,
      estimatedRushHourPrice: 0,
      estimatedNightHourPrice: 0,
      estimatedPriceReturn: 0,
      estimatedRushHourPriceReturn: 0,
      estimatedNightHourPriceReturn: 0,
      showEstimatedPrice: false,
      posts: [],
      directionsRenderer: null,
      time: null,
      timeMenu: false,
      carouselIndex: 0,
      countryCode: 230,
    };
  },

  computed: {
    validation() {
      return this.formData.pickup.length > 0;
    },
  },

  mounted() {
    this.setupMap();
    this.setupPickupDropoffInputSystem();
    this.checkExistingTripPayment();
  },
  methods: {
    scrollToElementTop(e) {
      if (window.innerWidth <= 768) {
        var elementRect = e.target.getBoundingClientRect();
        var top =
          elementRect.top +
          (document.documentElement.scrollTop || window.pageYOffset) -
          elementRect.height -
          (e.target.id == "searchFareDropOff" ? 170 : 73);
        window.scrollTo(0, top);
      }
    },
    setupPaymentScript() {
      // Add checkout script tag
      let checkoutScript = document.createElement("script");
      checkoutScript.setAttribute(
        "src",
        `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${this.checkoutId}`
      );
      document.head.appendChild(checkoutScript);
      this.cardSubmit = true;
    },
    checkExistingTripPayment() {
      if (this.$route.query && this.$route.query.resourcePath) {
        this.showLoader = true;
        const paymentStatusResponse = PaymentGatewayService.checkPaymentStatus({
          resourcePath: this.$route.query.resourcePath,
        });
        console.log("paymentStatusResponse: ", paymentStatusResponse);
        if (paymentStatusResponse) {
          TasksService.createNoAuthScheduledTask(
            JSON.parse(localStorage.getItem("currentTrip"))
          )
            .then((result) => {
              console.dir(result);
              localStorage.removeItem("currentTrip");
              this.onTripCreated();
            })
            .catch((error) => {
              console.log("createNoAuthScheduledTaskError", error);
              this.onTripCreated();
            });
        } else {
          this.onTripCreated();
        }
      }
    },
    setupMap() {
      this.directionsRenderer = new window.google.maps.DirectionsRenderer();
      this.map = new window.google.maps.Map(
        document.querySelector("#directionMap"),
        {
          zoom: 11,
          center: new window.google.maps.LatLng(-20.1644077, 57.4936861),
        }
      );
      this.directionsRenderer.setMap(this.map);
    },
    setupPickupDropoffInputSystem() {
      const searchFarePickUp = document.getElementById("searchFarePickUp");
      const searchFarePickUpAutoComplete =
        new window.google.maps.places.Autocomplete(searchFarePickUp, {
          // types: ["establishment"],
          componentRestrictions: { country: "mu" },
          fields: ["address_components", "name", "place_id"],
        });

      searchFarePickUpAutoComplete.addListener("place_changed", () => {
        this.pickupAddressFareLocation =
          searchFarePickUpAutoComplete.getPlace();
        this.pickFareLocation = this.pickupAddressFareLocation.name;
      });

      const searchFareDropOff = document.getElementById("searchFareDropOff");
      const searchFareDropOffAutoComplete =
        new window.google.maps.places.Autocomplete(searchFareDropOff, {
          // types: ["establishment"],
          componentRestrictions: { country: "mu" },
          fields: ["address_components", "name", "place_id"],
        });
      searchFareDropOffAutoComplete.addListener("place_changed", () => {
        this.dropoffAddressFareLocation =
          searchFareDropOffAutoComplete.getPlace();
        this.dropFareLocation = this.dropoffAddressFareLocation.name;

        this.calculateEstimatedFare();
      });
    },
    activate(num) {
      this.estimatedPrice = 0;
      this.estimatedPriceReturn = 0;
      this.estimatedRushHourPriceReturn = 0;
      this.estimatedRushHourPrice = 0;
      this.estimatedNightHourPriceReturn = 0;
      this.estimatedNightHourPrice = 0;
      this.pickFareLocation = "";
      this.dropFareLocation = "";
      this.isActive = num;
      if (num == 2) {
        this.carouselIndex = 0;
      } else {
        this.setupPickupDropoffInputSystem();
      }
    },
    clearDropOff() {
      this.dropFareLocation = "";
    },
    clearPickUp() {
      this.pickFareLocation = "";
    },
    bgHeaderImage() {
      setInterval(() => {
        for (let i = 0; i < this.imgs.length; i++) {
          return this.imgs[i];
        }
      }, 500);
      return this.imgs[0];
    },
    setActiveItem(index) {
      this.carouselIndex = index;

      // this.$refs.carousel.setActiveItem(index);
      if (
        index == 1 &&
        this.pickFareLocation !== "" &&
        this.dropFareLocation !== ""
      ) {
        this.calculatorHeading = `${this.pickFareLocation.substring(
          0,
          15
        )} - ${this.dropFareLocation.substring(0, 15)}`;
      } else {
        this.calculatorHeading = "Fare Estimator";
      }
    },
    calculateEstimatedFare() {
      const directionsService = new window.google.maps.DirectionsService();
      const request = {
        origin: { placeId: this.pickupAddressFareLocation.place_id },
        destination: { placeId: this.dropoffAddressFareLocation.place_id },
        provideRouteAlternatives: false,
        travelMode: "DRIVING",
        drivingOptions: {
          departureTime: new Date(new Date().getTime() + 5 * 60000),
          trafficModel: "pessimistic",
        },
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
      };
      const remoteConfig = firebase.remoteConfig();
      remoteConfig.settings = {
        minimumFetchIntervalMillis: 43200000,
        fetchTimeoutMillis: 120000,
      };

      directionsService.route(request, (result, status) => {
        if (status == "OK") {
          this.directionsRenderer.setDirections(result);
          const firstStep = result.routes[0].legs[0].steps[0];
          const lastStep =
            result.routes[0].legs[0].steps[
            result.routes[0].legs[0].steps.length - 1
            ];
          this.pickupLatLng = {
            lat: firstStep.start_location.lat(),
            lng: firstStep.start_location.lng(),
          };
          this.dropoffLatLng = {
            lat: lastStep.end_location.lat(),
            lng: lastStep.end_location.lng(),
          };

          // Get api estimated time
          const apiEstimatedTime = Math.floor(
            result.routes[0].legs[0].duration.value / 60
          );

          // Get api estimated distance
          const apiEstimatedDistance =
            result.routes[0].legs[0].distance.value / 1609;

          // Get district names
          let pickupDistrict =
            this.pickupAddressFareLocation.address_components.filter((item) =>
              item.types.includes("administrative_area_level_1")
            )[0];
          pickupDistrict = pickupDistrict ? pickupDistrict.long_name : null;
          let dropoffDistrict =
            this.dropoffAddressFareLocation.address_components.filter((item) =>
              item.types.includes("administrative_area_level_1")
            )[0];
          dropoffDistrict = dropoffDistrict ? dropoffDistrict.long_name : null;

          // Get route line segment
          const routeLineSegment = result.routes[0].legs[0].steps.map(
            (value, index) => {
              return index == 0
                ? [value.start_location.lng, value.start_location.lat]
                : [value.end_location.lng, value.end_location.lat];
            }
          );

          const calculateFareRequest = {
            pickupName: this.pickFareLocation,
            dropoffName: this.dropFareLocation,
            pickupLatitude: firstStep.start_location.lat(),
            pickupLongitude: firstStep.start_location.lng(),
            dropoffLatitude: lastStep.end_location.lat(),
            dropoffLongitude: lastStep.end_location.lng(),
            currentTime: "15:00",
            isDelivery: this.isActive == 2,
            pickupDistrict: pickupDistrict,
            dropoffDistrict: dropoffDistrict,
            apiEstimatedTime: apiEstimatedTime,
            apiEstimatedDistance: apiEstimatedDistance,
            routeLineSegment: routeLineSegment,
          };

          TasksService.calculateFare(calculateFareRequest).then((fareResponse) => {
            this.estimatedPrice = fareResponse.data.normalFare;
            this.estimatedPriceReturn = fareResponse.data.normalReturnFare;
            this.estimatedNightHourPrice = fareResponse.data.nightHourFare;
            this.estimatedNightHourPriceReturn =
              fareResponse.data.nightHourReturnFare;
            this.estimatedRushHourPrice = fareResponse.data.rushHourFare;
            this.estimatedRushHourPriceReturn =
              fareResponse.data.rushHourReturnFare;
          });
        }
      });
    },

    getInMultiple(noToConvert, multiple) {
      if (noToConvert % multiple > multiple / 5) {
        return Math.ceil(noToConvert / multiple) * multiple;
      } else {
        return Math.floor(noToConvert / multiple) * multiple;
      }
    },
    async validate() {
      this.submitted = true;
      if (this.time && this.date) {
        this.submitted = false;
        this.showLoader = true;
        const d = this.date;
        let hhMm = this.time.display.replace(" AM", "").replace(" PM", "").split(":");
        let hour = hhMm[0];
        let minutes = hhMm[1];
        let estimPrice = this.estimatedPrice || "0";
        hour = parseInt(hour, 10);
        let hour24 = Number(hour);

        // Add scheduled time to scheduled date
        d.setHours(hour24, Number(minutes));

        if (hour24 >= 8 && hour24 <= 15) {
          estimPrice = this.returnd
            ? this.estimatedPrice
            : this.estimatedPriceReturn;
        } else if (
          (hour24 >= 5 && hour24 <= 7) ||
          (hour24 >= 16 && hour24 <= 20)
        ) {
          estimPrice = this.returnd
            ? this.estimatedRushHourPrice
            : this.estimatedRushHourPriceReturn;
        } else {
          estimPrice = this.returnd
            ? this.estimatedNightHourPrice
            : this.estimatedNightHourPriceReturn;
        }
        minutes = parseInt(minutes, 10);
        if (this.returnd) {
          this.journeyType = "One-Way";
        } else {
          this.journeyType = "Return";
        }
        this.phone = `+${this.countryCode}${this.phone}`;
        const customerNameArray = this.customerName.split(" ");

        const data = {
          paymentMethod: this.paymentMethod,
          estimatedPrice: Number(estimPrice),
          userPhoneNo: this.phone.replace("+", ""),
          isReturnTask: !this.returnd,
          pickup: {
            latitude: this.pickupLatLng.lat,
            longitude: this.pickupLatLng.lng,
            address: this.pickFareLocation,
          },
          dropoff: {
            latitude: this.dropoffLatLng.lat,
            longitude: this.dropoffLatLng.lng,
            address: this.dropFareLocation,
          },
          scheduledTime: d.getTime(),
          firstName: customerNameArray[0],
          lastName: customerNameArray[1] ? customerNameArray[1] : "",
          noOfPersons: Number(this.instruction),
        };

        if (this.isActive == 2) {
          this.rideType = "1";
        }
        if (this.rideType == "1") {
          data.taskItems = [
            {
              name: this.deliveryItemType,
              type: this.deliveryItemType.toLowerCase(),
            },
          ];
          data.receiverPhoneNo = this.rphone;
        }

        if (this.paymentMethod === "Cash") {
          TasksService.createNoAuthScheduledTask(data)
            .then((result) => {
              console.dir(result);
              this.onTripCreated();
            })
            .catch((error) => {
              console.dir(error);
              this.onTripCreated();
            });
        } else {
          const checkoutSessionResponse =
            await PaymentGatewayService.createCheckoutSession({
              amount: estimPrice,
            });
          console.log("checkoutSessionResponse", checkoutSessionResponse);
          checkoutSessionResponse.id;
          this.setupPaymentScript();
          localStorage.setItem("currentTrip", JSON.stringify(data));
        }
      }
    },
    onTripCreated() {
      this.carouselIndex = 0;
      this.date = null;
      this.hours = null;
      this.minutes = null;
      this.time = null;
      this.journeyType = null;
      this.pickFareLocation = "";
      this.dropFareLocation = "";
      this.customerName = null;
      this.showModal = true;
      this.showLoader = false;
      this.clearFareForm();
      setTimeout(() => {
        this.isActive = 1;
      }, 3000);
    },

    reset() {
      this.$refs.form.reset();
    },
    fareValidate() {
      if (this.showEstimatedPrice) {
        this.pickLocation = this.pickFareLocation;
        this.dropLocation = this.dropFareLocation;
        this.intDialogVisible = true;
      } else {
        this.pickLocation = "";
        this.dropLocation = "";
        this.intDialogVisible = true;
      }
    },
    clearFareForm() {
      this.showEstimatedPrice = false;
      this.estimatedPrice = 0;
      this.estimatedRushHourPrice = 0;
      this.estimatedNightHourPrice = 0;
      this.estimatedPriceReturn = 0;
      this.estimatedRushHourPriceReturn = 0;
      this.estimatedNightHourPriceReturn = 0;
      this.intDialogVisible = false;
      this.pickupAddressFareLocation = undefined;
      this.dropoffAddressFareLocation = undefined;
      this.pickLocation = null;
      this.dropLocation = null;
    },
    toogleSwitch() {
      if (this.returnd) {
        this.returnd = false;
      } else {
        this.returnd = true;
      }
    },
    onCountrySelect({ dialCode }) {
      this.countryCode = dialCode;
    },
  },
};
</script>
<style scoped>
.active {
  border: 1px solid #a3a4a4;
  padding: 5px 15px;
  border-radius: 7px;
}

.rsize:hover {
  cursor: pointer;
}

.car.rsize {
  margin-top: 60px;
}

.isize:hover {
  cursor: pointer;
}

.cat-header {
  background-color: #f7f7f7;
  position: relative;
  border-bottom: 1px solid #eeeeee;
  /* top: -1px; */
}

.tab__title {
  list-style: none;
}

.driver-header {
  width: 100%;
  min-height: 60rem;
  background-image: url("../assets/imgs/header-img_digital_art_x4.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

.driver-header.mapShown,
.header-driver-transparent-bg.mapShown {
  background-image: none;
}

.header-driver-transparent-bg {
  width: 100%;
  height: 100%;
  background-image: url("../assets/imgs/driver/driver-bg-transparent-1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

#directionMap {
  width: 65%;
  height: 738px;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 35%;
}

.card {
  border: none;
  z-index: 101;
  margin-left: 10%;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
}

.card-header {
  border-bottom: none;
  color: #fff;
  background-color: #4f5cd1;
}

.card-body {
  background-color: #fdfdfd;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media screen and (max-width: 786px) {
  .header-driver-transparent-bg {
    background-image: none;
  }

  .driver-header {
    background-image: none;
    height: auto;
    font-size: 16px;
  }

  .switch-btn:after {
    right: 10px;
  }

  .img-bg {
    height: 250px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../assets/imgs/header-img_digital_art_x4.png");
  }

  .card {
    margin-left: 0;
  }

  #directionMap {
    width: 100%;
    height: 273px;
    z-index: 100;
    left: 0;
    position: absolute;
    top: 799px;
  }

  .header-bg-transparent {
    background-color: #0c0c0c9f;
    height: 100%;
    width: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .img-bg .header-bg-transparent {
    border-radius: 0;
  }
}

.dropoff-pickup-div {
  position: relative;
}

.form-control.text-muted {
  padding-top: 0.75rem;
}

.card-form:focus {
  border: none;
  background-color: #f6f6f6;
  box-shadow: none;
}

.card-form::placeholder {
  color: #6e6e6e;
  font-size: 18px;
}

.clear-buttn {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.switch-container {
  position: relative;
}

.switch-btn {
  min-width: 178px;
  height: 30px;
  border-radius: 15px;
}

.switch-btn p {
  font-size: 14px;
}

.switch-btn:after {
  top: 5px;
  right: 4px;
  height: 14px;
  width: 15px;
  content: "";
  position: absolute;
  border-radius: 100%;
  transition: all 0.3s;
  background-color: #fafafa;
}

.switch-btn.return {
  width: 205px;
}

.switch-btn.return:after {
  left: 4px;
}

.color-green {
  color: #289d73;
  font-weight: bolder;
}

.underline {
  text-decoration: underline;
}

span.underline {
  margin-left: 10px;
  cursor: pointer;
}

.size {
  font-size: 20px;
}

.isize {
  font-size: 25px;

  padding-left: 15px;
}

img.isize {
  width: 60px;
  padding-left: 0;
}

.car.isize {
  position: absolute;
  display: block;
  width: 120px;
  left: -30px;
}

.isize:active {
  color: #fafafa;
}

.row.position-relative.number .position-absolute {
  z-index: 101;
  top: 5px;
  background-color: #f6f6f6;
}

.card-form.border-radjust.number {
  padding-left: 55px;
}

.cal-field {
  font-size: 15px;
  border: none;
  box-shadow: none;
  background-color: #f6f6f6;
  width: 90%;
  height: 65px;
  padding-bottom: 1px;
  padding-top: 3px;
  margin-left: 40px;
}

.cal-field:hover {
  border-bottom: 1px solid;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.cal-field::placeholder {
  font-size: 20px;
}

.clear-btn {
  cursor: pointer;
  position: absolute;
  top: -12px;
  right: 65px;
  font-size: 30px;
  opacity: 0.8;
}

.text-position {
  width: 30%;
  position: absolute;
  top: -15px;
  left: 42px;
}

.text-position1 {
  width: 30%;
  position: absolute;
  top: -15px;
  left: 15px;
}

.text-position2 {
  width: 100%;
  position: absolute;
  top: -18px;
  left: 16px;
}

.text-position3 {
  width: 30%;
  position: absolute;
  top: -15px;
  left: 15px;
}

.text-position4 {
  width: 30%;
  position: absolute;
  top: -15px;
  left: -15px;
}

.size-lable {
  font-size: 18px;
  font-weight: bold;
  color: #8b37ff;
}

.text-colour {
  color: #8b37ff;
  font-weight: bold;
  font-size: 18px;
}

.size-icon {
  font-size: 23px;
}

.stick-position {
  position: absolute;
  right: 15px;
  border-left: 1px solid;
  border-bottom: 1px solid;
}

.border-btm {
  border-bottom: 1px solid;
}

.width-adjust1 {
  width: 100%;
}

.width-adjust2 {
  width: 100%;
}

.pad-adj {
  position: relative;
  left: 12px;
  top: 5px;
}

.font-18 {
  font-size: 17px;
  font-weight: bold;
}

@media screen and (max-width: 500px) {
  .clear-btn {
    cursor: pointer;
    position: absolute;
    top: 2px;
    right: 40px;
    font-size: 30px;
  }
}

@media screen and (min-width: 786px) {
  .header-title span {
    font-size: 12px !important;
  }

  .header-title h2 {
    font-size: 22px !important;
  }

  .driver-header {
    margin-top: -200px;
  }
}

@media screen and (max-width: 1100px) {
  .maintainance span {
    font-size: 12px !important;
  }

  .maintainance h2 {
    font-size: 22px !important;
  }

  .header-title span {
    font-size: 12px !important;
  }

  .header-title h2 {
    font-size: 22px !important;
  }

  .pad-adj {
    position: relative;
    right: 45px;
  }
}

@media screen and (max-width: 983px) {

  /* .header-title span {
    font-size: 12px !important;
  } */
  .maintainance span {
    font-size: 12px !important;
  }

  .maintainance h2 {
    font-size: 22px !important;
  }

  .header-title h2 {
    font-size: 22px !important;
  }

  .pad-adj {
    position: relative;
    left: 60px;
  }

  .row.position-relative.number .position-absolute {
    z-index: 101;
    top: 5px;
    left: 10px;
    background-color: #f6f6f6;
  }

  .card-form.border-radjust.number {
    padding-left: 65px;
  }
}

@media screen and (min-width: 1114px) {

  /* .header-title span {
    font-size: 12px !important;
  } */
  .maintainance span {
    font-size: 12px !important;
  }

  .maintainance h2 {
    font-size: 22px !important;
  }

  .header-title h2 {
    font-size: 22px !important;
  }

  .pad-adj {
    position: relative;
    right: 70px;
  }
}

@media screen and (max-width: 1200px) {

  /* .header-title span {
    font-size: 12px !important;
  } */
  .maintainance span {
    font-size: 12px !important;
  }

  .maintainance h2 {
    font-size: 22px !important;
  }

  .header-title h2 {
    font-size: 22px !important;
  }

  .d-flex {
    flex-direction: column;
  }

  .d-flex.no-column {
    flex-direction: row;
  }
}

.form-input-adapt {
  width: 100%;
}

.form-input-adapt input {
  border: none !important;
  background: transparent;
  margin: 10px 15px 10px 15px;
  outline: none;
  cursor: pointer;
  padding: 0px !important;
}

@media screen and (max-width: 736px) {

  /* .header-title span {
    font-size: 8px !important;
  } */
  .maintainance span {
    font-size: 10px !important;
  }

  .maintainance h2 {
    font-size: 15px !important;
  }

  .whatsapp-btn {
    width: 150px !important;
  }

  .header-title h2 {
    font-size: 18px !important;
  }

  .mob-header {
    padding-top: 0;
  }

  .cal-field {
    margin-left: 35px;
  }

  .clear-btn {
    position: absolute;
    top: -13px;
    right: 43px;
  }

  .width-adjust2 {
    width: 92%;
  }

  .padding-adjust {
    padding-top: 50px;
  }

  .text-position4 {
    width: 30%;
    position: absolute;
    top: -15px;
    left: 14px;
  }

  .stick-position {
    border-left: none;
  }
}

@media screen and (max-width: 568px) {

  .maintainance span {
    font-size: 10px;
  }

  .maintainance h2 {
    font-size: 20px;
  }

  .whatsapp-btn {
    width: 145px !important;
  }

  .whatsapp-btn a {
    font-size: 15px !important;
  }

  .whatsapp-btn i {
    font-size: 15px !important;
  }

  .header-title span {
    font-size: 10px;
  }

  .header-title h2 {
    font-size: 20px;
  }

  .order-btn {
    margin-top: 60px;
  }
}

@media screen and (max-width: 375px) {
  .whatsapp-btn {
    width: 95px !important;
  }

  .whatsapp-btn a {
    font-size: 12px !important;
  }

  .whatsapp-btn i {
    font-size: 12px !important;
  }

  .header-title span {
    font-size: 10px;
  }

  .header-title h2 {
    font-size: 20px;
  }

  .order-btn {
    margin-top: 60px;
  }
}

/* @media screen and (max-width: 770px) {
  .switch-btn:after {
    left: 21%;
  }
}

@media screen and (max-width: 553px) {
  .switch-btn:after {
    left: 30%;
  }
}
@media screen and (max-width: 400px) {
  .switch-btn:after {
    left: 34%;
  }
}
@media screen and (max-width: 320px) {
  .switch-btn:after {
    left: 43%;
  }
} */
.loader {
  color: #4f5cd1;
  font-size: 10px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load4 {

  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }

  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }

  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }

  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@keyframes load4 {

  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }

  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }

  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }

  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.icon-span {
  background-color: transparent;
  margin-top: -50px;
  border-radius: 50%;
  text-align: center;
}

.icon-span i {
  font-size: 120px;
  color: rgb(40, 167, 69);
}

.list-group-horizontal.cat-header {
  flex-direction: row !important;
}

.d-flex.flex-column.active {
  padding-bottom: 15px;
  border-bottom: 2px solid black;
  margin-bottom: 0.5px;
}

.btn-shine {
  transform: translate(-50%, -50%);
  color: #ffffff;
  background: linear-gradient(to right, #4d4d4d 0, white 10%, #4d4d4d 20%);
  background-position: 0;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;

  font-size: 16px;
}

.header-title span {
  font-size: 14px;
  color: red;
}

.header-title h2 {
  font-size: 26px;
}

/* whatsapp-contact */
.float-button {
  text-decoration: none;
  margin-top: 10px;
  padding: 10px;
  color: #fff;
  background-color: #25be0c;
  font-weight: 700;
  border-radius: 10px;
  z-index: 100;
  font-size: 16px;
  /* box-shadow: 1px 2px 5px 2px rgba(30, 30, 30, 0.3); */
  transition: all 0.3s ease-out;
  text-align: center;
}

.float-button i {
  font-size: 18px !important;
}


.float-button:hover {
  background-color: #22b50b;
  color: #fff;
}

.whatsapp-number {
  text-align: right;
  font-size: 12px;
}

.fa-whatsapp {
  font-size: 20px !important;
  padding-right: 5px;
  padding-left: 5px;
}

@keyframes shine {
  0% {
    background-position: 0;
  }

  60% {
    background-position: 180px;
  }

  100% {
    background-position: 180px;
  }
}

.maintainance span {
  font-size: 14px;
  color: red;
}

.maintainance h2 {
  font-size: 26px;
}

/* whatsapp-contact */
.float-button {
  text-decoration: none;
  margin-top: 35px;
  padding: 10px;
  color: #fff;
  background-color: #25be0c;
  font-weight: 700;
  border-radius: 10px;
  z-index: 100;
  font-size: 18px;
  /* box-shadow: 1px 2px 5px 2px rgba(30, 30, 30, 0.3); */
  transition: all 0.3s ease-out;
  text-align: center;
}


.float-button:hover {
  background-color: #22b50b;
  color: #fff;
}

.whatsapp-number {
  text-align: right;
  font-size: 12px;
}

.fa-whatsapp {
  font-size: 20px !important;
  padding-right: 5px;
  padding-left: 5px;
}
</style>
